import { useEffect, useState } from "react";
import { GoCheckCircleFill, GoClockFill, GoSkipFill } from "react-icons/go";
import { Area, AreaChart, Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { HttpNoData } from "../../Core";
import styles from './AnalyticLasers.module.css';
import { FaInfoCircle } from "react-icons/fa";
import { Alert, Modal, Stack } from "react-bootstrap";

function AnalyticLasers(props) {

    const [jwt, setJwt] = useState(props.jwt);

    const [pulls, setPulls] = useState([]);
    const [sumData, setSumData] = useState([]);
    const [layoutData, setLayoutData] = useState([]);
    const [machinesOnly, setMachinesOnly] = useState([]);
    const [machines, setMachines] = useState([]);
    const [selectDate, setSelectDate] = useState(new Date().toLocaleDateString().split('.').reverse().join('-'));

    const InputDatas = async (machines, dateString) => {

        const responsePulls = await HttpNoData('/api/v0/lasers/pulls?date=' + dateString, 'GET', jwt);

        if (!responsePulls.statusSuccessful) {
            console.log(responsePulls.error);
            return;
        }

        setPulls(responsePulls.data);
        const machinePulls = responsePulls.data;

        var resultMachines = [];
        var resultSum = [];
        var resultStable = [];

        const currentDateTick = new Date(dateString.split('.').reverse().join('-') + 'T00:00:00.0').getTime();

        for (let machineI = 0; machineI < machines.length; machineI++) {

            const currentMachine = machines[machineI];
            const pullIndex = machinePulls.findIndex(o => +o.machineId === +currentMachine.id);

            if (pullIndex === -1) {
                resultMachines.push({ 
                    id: currentMachine.id,
                    isLive: currentMachine.isLive,
                    name: currentMachine.name, 
                    pulls: [] });
                continue;
            }

            const currentPulls = machinePulls[pullIndex].pulls;

            var currentArray = [];
            currentArray.length = 1440;

            for (let i = 0; i < currentPulls.length; i++) {

                const currentPull = currentPulls[i];

                const startDate = new Date(currentPull.start);
                const endDate = new Date(currentPull.end)
                const nextDate = currentDateTick + 86400000;

                if (currentDateTick < startDate && nextDate > endDate)
                    currentArray.fill({ value: 1, state: currentPull.state }, (startDate - currentDateTick) / 60000, (endDate - currentDateTick) / 60000);
                else if (startDate <= currentDateTick)
                    currentArray.fill({ value: 1, state: currentPull.state }, 0, (endDate - currentDateTick) / 60000); // 60000 = one minutes
                else if (endDate >= nextDate)
                    currentArray.fill({ value: 1, state: currentPull.state }, (startDate - currentDateTick) / 60000, 1440);
            }

            for (let i = 0; i < currentArray.length; i++) {
                let name = '';

                let hours = Math.floor(i / 60);
                let minutes = i - (hours * 60);

                name = hours + ':' + (minutes >= 10 ? minutes : '0' + minutes);

                const current = currentArray[i];
                const currentState = current ? current.state : 'none';
                const vector = current ? current.value : 0;

                if (resultSum[i])
                    resultSum[i].value += vector;
                else resultSum[i] = { name: name, value: vector };

                if (!resultStable[i] || +resultStable[i].value !== 1)
                    resultStable[i] = { name: name, value: vector };

                currentArray[i] = { name: name, value: vector, state: currentState };
            }

            resultMachines.push({
                id: currentMachine.id,
                name: currentMachine.name,
                isLive: currentMachine.isLive,
                pulls: currentArray,
                statistic: {
                    fill: (currentArray.filter(o => o.value > 0).length / currentArray.length * 100).toFixed(2),
                    success: (currentArray.filter(o => o.value > 0 && o.state === 'good').length / currentArray.length * 100).toFixed(2),
                    error: (currentArray.filter(o => o.value > 0 && o.state === 'var').length / currentArray.length * 100).toFixed(2)
                }
            });
        }

        setSumData(resultSum);
        setMachines(resultMachines);
        setLayoutData(resultStable);
    }

    useEffect(() => {

        async function DidMount() {

            const responseMachines = await HttpNoData('/api/v0/lasers', 'GET', jwt);

            const machines = responseMachines.data;

            if (!responseMachines.statusSuccessful)
                return;

            setMachinesOnly(machines);
            await InputDatas(machines, selectDate);
        }

        DidMount();
    }, []);

    const ChangeDate = async (date) => {

        setSelectDate(date);
        await InputDatas(machinesOnly, date);
    }

    const ContentItem = (props) => {

        const { title, content } = props;

        return <div className={styles.contentItem}>
            <div className={styles.contentTitle}>{title}</div>
            <div className={styles.contentChild}>
                {content}
            </div>
        </div>
    }

    const UpdateMachineLive = async(machineId, newStateLive) => {
        const response = await HttpNoData('/api/v0/lasers/' + machineId + '?isLive=' + newStateLive, 'PUT', jwt);

        if(!response.statusSuccessful)
            console.log(response.error);
    }

    const Lasers = () => {

        const [currentViewTypeChart, setCurrentViewTypeChart] = useState('all');

        const ChartView = ({ item }) => {

            const [isInfo, setIsInfo] = useState(false);
            const [currentPulls, setCurrentPulls] = useState((pulls.find(o => o.machineId == item.id)?.pulls) ?? []);

            const colors = {
                stroke: {
                    all: '#8884d8',
                    success: 'green',
                    error: 'red'
                },
                fill: {
                    all: "url(#colorUv)",
                    success: 'url(#colorSuccess)',
                    error: 'url(#colorError)'
                }
            };

            const result = {
                all: (record) => record.value,
                success: (record) => record.state == 'good' ? record.value : 0,
                error: (record) => record.state == 'var' ? record.value : 0
            };

            const statisticFill = item.statistic ? item.statistic.fill : 0;
            const statisticSuccess = item.statistic ? item.statistic.success : 0;
            let statisticError = item.statistic ? item.statistic.error : 0;

            const pieData = [{
                name: 'Работа',
                value: Math.round(statisticFill)
            }, {
                name: 'Бездействие',
                value: Math.round(100 - statisticFill)
            }];

            const pieStatusData = [{
                name: 'Успешно',
                value: Math.round(statisticSuccess)
            }, {
                name: 'Ошибка',
                value: Math.round(statisticError)
            }, {
                name: 'Бездействие',
                value: Math.round(100 - statisticSuccess - statisticError)
            }];

            const COLORS_GO = [
                '#8884d8',
                'gray'
            ];

            const COLORS_SUCCESS = [
                'green',
                'red',
                'gray',
            ]

            const rectMetr = 200;

            const ChartPie = ({ data, width, height, innerRadius, outerRadius, fnColor, fill, isElements }) => {

                return <PieChart width={width} height={height}>
                    <Pie data={data} dataKey="value" cx="50%" cy="50%" innerRadius={innerRadius} outerRadius={outerRadius} fill={fill} >
                        {data.map((entry, index) => <Cell key={index} fill={fnColor(entry, index)} />)}
                    </Pie>
                    {
                        isElements ?
                            <Legend
                            payload={
                                data.map(
                                (item, index) => ({
                                    id: item.name,
                                    type: "square",
                                    value: `${item.name} (${item.value}%)`,
                                    color: fnColor(item, index)
                                })
                                )
                            }
                            />
                            : null
                    }
                </PieChart>;
            }

            return <ContentItem title={<div className={styles.machineHead}>
                    <div>{item.name}</div>
                    <input className={styles.machineCheckLive} 
                    type="checkbox" 
                    defaultChecked={item.isLive}
                    onChange={(e) => UpdateMachineLive(item.id, e.target.checked)}/>
                    <FaInfoCircle onClick={() => setIsInfo(true)} style={{marginLeft: 10}}/>
                </div>}
                content={
                <div className={styles.chartBody}>
                    <ResponsiveContainer key={item.id} width={'100%'} height={100}>
                        <AreaChart margin={{ bottom: 0, left: 20, right: 20, top: 0 }} data={item.pulls}>
                            <XAxis dataKey="name" interval={249} />
                            <Tooltip />
                            <Area type="stepAfter" name="Работа" dataKey={(record) => result[currentViewTypeChart](record)}
                                stroke={colors.stroke[currentViewTypeChart]} fillOpacity={1} fill={colors.fill[currentViewTypeChart]} />
                        </AreaChart>
                    </ResponsiveContainer>
                    <div className={styles.chartStatistic}>
                        <ChartPie data={pieData} width={rectMetr} height={rectMetr} isElements={true}
                            fnColor={(entry, localIndex) => COLORS_GO[localIndex % COLORS_GO.length]} />
                        <ChartPie data={pieStatusData} width={rectMetr} height={rectMetr} isElements={true}
                            fnColor={(entry, localIndex) => COLORS_SUCCESS[localIndex % COLORS_SUCCESS.length]} />
                    </div>
                    {
                    isInfo ? 
                    <Modal show={isInfo} onHide={() => setIsInfo(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{item.name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Stack gap={1}>
                            {
                                currentPulls && currentPulls.length > 0 ?
                                currentPulls.map((o, i) => <Alert variant={o.state == 'good' ? 'success' : 'danger'}>{(i + 1) + ')'} {selectDate.split('-').reverse().join('.').slice(0, 5)} {new Date(o.start).toTimeString().split(' ')[0]} - {new Date(o.end).toTimeString().split(' ')[0]} ({o.timeSpan})</Alert>)
                                : <Alert variant="warning">Нет записей</Alert>
                            }
                            </Stack>
                        </Modal.Body>
                    </Modal>
                    : ''
                }
                </div>} >
                </ContentItem>;
        };

        const currentMachineCharts = machines.map(o => <ChartView item={o} key={o.id} />);

        return <div className={styles.contentLasers}>
            <ContentItem title={'Фильтры'} content={
                <div className={styles.filters}>
                    {
                        [{ value: 'all', icon: GoClockFill, color: 'gray' },
                        { value: 'success', icon: GoCheckCircleFill, color: 'green' },
                        { value: 'error', icon: GoSkipFill, color: 'red' }]
                            .map((button, index) => <button.icon style={{ color: button.color }} key={index} className={currentViewTypeChart === button.value ?
                                styles.filterActive : styles.filter} onClick={() => setCurrentViewTypeChart(button.value)} />)
                    }
                </div>
            } />
            {currentMachineCharts}
        </div>
    };

    const Analyze = () => {

        const [isSumView, setIsSumView] = useState(false);

        const sumDataChart = <ResponsiveContainer width={'100%'} height={300}>
            <AreaChart margin={{ bottom: 0, left: 20, right: 20, top: 0 }} data={sumData}>
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorError" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="red" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="red" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorSuccess" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="green" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="green" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <XAxis dataKey="name" interval={249} />
                <Tooltip />
                <Area type="stepAfter" dataKey="value" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
            </AreaChart>
        </ResponsiveContainer>

        const stableChart = <ResponsiveContainer width={'100%'} height={100}>
            <AreaChart margin={{ bottom: 0, left: 20, right: 20, top: 0 }} data={layoutData}>
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorError" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="red" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="red" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorSuccess" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="green" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="green" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <XAxis dataKey="name" interval={249} />
                <Tooltip />
                <Area type="stepAfter" dataKey="value" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
            </AreaChart>
        </ResponsiveContainer>

        return <ContentItem title={'Аналитика'} content={
            <div className={styles.contentAnalyze}>
                <button className={styles.moveState} onClick={() => setIsSumView(!isSumView)}>{isSumView ? 'Рентабельность' : 'Сумма'}</button>
                {
                    isSumView ? sumDataChart : stableChart
                }
            </div>} />;
    }

    return <div className={styles.psevdoBody}>
        <div className={styles.content}>
            <input className={styles.selectDate} value={selectDate} onChange={(e) => ChangeDate(e.target.value)} type="date" />
            <Analyze />
            <Lasers />
        </div>

    </div>
}

export default AnalyticLasers;