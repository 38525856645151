import { useEffect } from "react";
import NavBar from "./components/NavBar/NavBar";

export default function App() {


	useEffect(() => {
		Number.prototype.toFixedNoRounding = function(n) {
			const reg = new RegExp(`^-?\\d+(?:\\.\\d{0,${n}})?`, 'g')
			const a = this.toString().match(reg)[0];
			const dot = a.indexOf('.');
		  
			if (dot === -1) {
			  return a + '.' + '0'.repeat(n);
			}
		  
			const b = n - (a.length - dot) + 1;
		  
			return b > 0 ? (a + '0'.repeat(b)) : a;
		  }

		  Date.prototype.addHours = function(h) {
			this.setTime(this.getTime() + (h*60*60*1000));
			return this;
		  }
		  
	}, []);
	
	return <NavBar/>;
}